<template>
  <div class="container px-0 h-100">
    <div class="wide-box-full">
      <div class="card border-radius-1 mb-4 pb-4">
        <div
          class="card-header-profile border-bottom d-flex align-items-end pb-4"
        >
          <div class="card-title-profile flex-grow-1">
            <div class="d-flex flex-row">
              <div class="px-2">
                <img src="/media/brand/profile-avatar.png" alt="" />
              </div>
              <div class="p-4 profile-fullname align-middle">
                Izabela<br />
                Słowikowska
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">113 zł</h3>
                  <span class="sub-title p-1">Prowizja 09/21</span>
                </div>
              </div>
              <div class="px-4">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">10 polis</h3>
                  <span class="sub-title p-1">Wznowienia 09/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">30 polis</h3>
                  <span class="sub-title p-1">Sprzedaż 08/21</span>
                </div>
              </div>
              <div class="px-4 ">
                <div class="bordered-block-profile">
                  <h3 class="text-secondary p-1">525 zł</h3>
                  <span class="sub-title p-1">Prowizja 08/21</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex justify-content-center flex-row nav-tabs profile-nav-tabs">
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-account' }">
                Konto
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-policies' }">
                Polisy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-renewals' }">
                Wznowienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-issues' }">
                Sprawy
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-leads' }">
                Leady
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-settlements' }">
                Rozliczenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link active">
              <router-link :to="{ name: 'profile-calendar' }">
                Kalendarz
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-trainings' }">
                Szkolenia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-permissions' }">
                Uprawnienia
              </router-link>
            </div>
            <div class="profile-nav-link nav-link">
              <router-link :to="{ name: 'profile-rodoidd' }">
                RODO/IDD
              </router-link>
            </div>
          </div>

          copy calendar content here
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "ProfileCalendarPageView",
  data() {
    return {
      selected: [], // Must be an array reference!
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
